/* You can add global styles to this file, and also import other style files */
@import "theme";

@font-face {
  font-family: "Gibson";
  src: url("../assets/fonts/canada_type_-_gibson-regular.woff2") format("woff2"),
    url("../assets/fonts/canada_type_-_gibson-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gibson";
  src: url("../assets/fonts/canada_type_-_gibson-bold.woff2") format("woff2"),
    url("../assets/fonts/canada_type_-_gibson-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gibson";
  src: url("../assets/fonts/canada_type_-_gibson-bolditalic.woff2") format("woff2"),
    url("../assets/fonts/canada_type_-_gibson-bolditalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gibson";
  src: url("../assets/fonts/canada_type_-_gibson-italic.woff2") format("woff2"),
    url("../assets/fonts/canada_type_-_gibson-italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gibson";
  src: url("../assets/fonts/canada_type_-_gibson-light.woff2") format("woff2"),
    url("../assets/fonts/canada_type_-_gibson-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gibson";
  src: url("../assets/fonts/canada_type_-_gibson-lightit.woff2") format("woff2"),
    url("../assets/fonts/canada_type_-_gibson-lightit.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gibson";
  src: url("../assets/fonts/canada_type_-_gibson-semibold.woff2") format("woff2"),
    url("../assets/fonts/canada_type_-_gibson-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gibson";
  src: url("../assets/fonts/canada_type_-_gibson-semiboldit.woff2") format("woff2"),
  url("../assets/fonts/canada_type_-_gibson-semiboldit.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

body {
  font-family: "Gibson", sans-serif;
  overflow-x: hidden;
  margin:0;
  line-height: 1.5;
  text-align: left;
}

.background-off-white {
  background-color: var(--surface-b);
}

.background-primary {
  background-color: var(--primary-400);
}

.primary-title {
  color: var(--primary-400);
  font-size: 32px;
  font-weight: 600;
  line-height: 1.15;
}
.text-20 {
  font-size: 19px;
}
.text-24 {
  font-size: 20px;
}
.text-30 {
  font-size: 22px;
}
.container{
  max-width : 1142px;
  margin:0 auto;
}
.p-component{
  font-family: Gibson,sans-serif !important;

}

/********************* LOADER : Start  *********************/ 

.custom-spinner.p-progress-spinner{
  width:60px;
  height:60px;
}

.custom-spinner .p-progress-spinner-circle {
  stroke: #12ba98!important;
}

/********************* LOADER : End  *********************/ 

/* INPUT FILED */ 
.p-inputtext {
  &.ng-invalid {
    &.ng-touched{
      border:1px solid #f5333f !important;
    }
  }
}

@media only screen and (min-width: 576px) {
  .primary-title {
    font-size: 36px;
  }
  .text-20 {
    font-size: 19.5px;
  }
  .text-24 {
    font-size: 21px;
  }
  .text-30 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .primary-title {
    font-size: 40px;
  }
  .text-24 {
    font-size: 22px;
  }
  .text-30 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 992px) {
  .primary-title {
    font-size: 44px;
  }
  .text-20 {
    font-size: 20px;
  }
  .text-24 {
    font-size: 23px;
  }
  .text-30 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1200px) {
  .primary-title {
    font-size: 48px;
  }
  .text-24 {
    font-size: 24px;
  }
  .text-30 {
    font-size: 30px;
  }
}
